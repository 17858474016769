<template>
	<v-dialog persistent max-width="1385px" v-model="editDialog" @save="saveCarDetails" scrollable>
		<template v-slot:activator="{ on, attrs }">
			<v-btn x-small outlined color="teal" class="mx-2 text-capitalize" title="Güncelle" v-bind="attrs" v-on="on">
				<v-icon class="mr-1" v-text="'fas fa-pen'" size="8"></v-icon>Güncelle
			</v-btn>
		</template>
		<v-card>
			<v-card-title> Araç ve Hasar Bilgileri </v-card-title>
			<v-card-text>
				<div class="d-flex justify-center align-center fill-height" v-if="isLoading">
					<v-progress-circular :size="50" :width="5" color="teal" indeterminate></v-progress-circular>
				</div>
				<v-row dense v-else>
					<v-col cols="12" md="4">
						<v-row dense>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Year"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.year"
									:items="getYears"
									@click="comboboxClick"
									@change="yearChanged(carInfo.year, false)"
								>
								</v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Marka"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.brand"
									:items="getBrands"
									:returnObject="true"
									:disabled="brandIsDisabled"
									@click="comboboxClick"
									@change="brandChanged(carInfo.brand, false)"
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Model"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.model"
									:items="getModels"
									:returnObject="true"
									:disabled="modelIsDisabled"
									@click="comboboxClick"
									@change="modelChanged"
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Kasa Tipi"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.bodyType"
									:items="getBodyTypes"
									:returnObject="true"
									:disabled="bodyTypeIsDisabled"
									@click="comboboxClick"
									@change="bodyTypeChanged"
								>
								</v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Vites"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.transmissionType"
									:items="getTransmissionTypes"
									:returnObject="true"
									:disabled="transmissionTypeIsDisabled"
									@click="comboboxClick"
									@change="transmissionTypeChanged"
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Yakıt"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.fuelType"
									:items="getFuelTypes"
									:returnObject="true"
									:disabled="fuelTypeIsDisabled"
									@click="comboboxClick"
									@change="fuelTypeChanged"
								>
								</v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Versiyon"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.version"
									:items="getVersions"
									:disabled="versionIsDisabled"
									:returnObject="true"
									@click="comboboxClick"
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-text-field
									color="teal"
									label="Kilometre"
									outlined
									hide-details
									flat
									dense
									class="rounded-lg"
									v-model="carInfo.kilometers"
								></v-text-field>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									label="Renk"
									outlined
									item-text="value"
									item-value="id"
									hide-details
									flat
									dense
									class="rounded-lg"
									clearable
									:returnObject="false"
									v-model="carInfo.colorId"
									:items="getColors"
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-select
									color="teal"
									:items="getCities"
									outlined
									label="Şehir seçiniz"
									v-model="carInfo.cityId"
									item-text="citName"
									item-value="citId"
									:return-object="false"
									hide-details="auto"
									dense
								></v-select>
							</v-col>
							<v-col cols="12" sm="6" class="py-4">
								<v-text-field-money
									v-model="carInfo.additionalDamageCost"
									v-bind:label="'Tramer tutarı giriniz'"
									v-bind:properties="{
										color: 'teal',
										outlined: true,
										dense: true,
										flat: true,
										class: 'rounded-lg',
										hideDetails: 'auto',
									}"
									v-bind:options="{
										locale: 'tr-TR',
										precision: 0,
									}"
								/>
							</v-col>
						</v-row>
					</v-col>
					<v-col cols="12" md="8">
						<damage-wizard
							:key="`edit-dialog-damage-${damageComponentKey}`"
							ref="damageComponent"
							:md-size="6"
							:sm-size="6"
							:preset.sync="presetData"
							:editMode="true"
						/>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red darken-1" text @click="cancel">
					Vazgeç
				</v-btn>
				<v-btn color="green darken-1" text @click="saveCarDetails">
					Kaydet
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { sellStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import {
	GET_WIZARD_YEARS,
	GET_WIZARD_DATA,
	SET_CITY_LIST,
	RESET_STATE,
	FETCH_COLORS,
} from '@/store/modules/carWizard.module';
import DamageWizard from '@/components/enduser/wizard/Damage.vue';
import { validationMixin } from 'vuelidate';
import infoValidationMixin from '@/mixins/validations/carWizard/infoValidationMixin';

export default {
	name: 'CarEditDialog',
	components: { DamageWizard },

	mixins: [validationMixin, infoValidationMixin],
	data() {
		return {
			editDialog: false,
			carInfo: {
				key: null,
				year: null,
				brand: null,
				model: null,
				bodyType: null,
				transmissionType: null,
				fuelType: null,
				version: null,
				kilometers: null,
				colorId: null,
				additionalDamageCost: null,
				cityId: null,
			},
			initialized: false,
			damageComponentKey: 1,
			firstLoad: true,
		};
	},
	computed: {
		...mapGetters([
			'isAuthUser',
			'carPreset',
			'darkTemplate',
			'getYears',
			'getBrands',
			'getModels',
			'getBodyTypes',
			'getTransmissionTypes',
			'getFuelTypes',
			'getVersions',
			'getColors',
			'getCities',
		]),
		isCarUpdateButtonShow() {
			return this.carPreset.carCurrentWzsId <= sellStep.PAYMENT;
		},
		brandIsDisabled() {
			return this.getBrands.length === 0;
		},
		modelIsDisabled() {
			return this.getModels.length === 0;
		},
		bodyTypeIsDisabled() {
			return this.getBodyTypes.length === 0;
		},
		transmissionTypeIsDisabled() {
			return this.getTransmissionTypes.length === 0;
		},
		fuelTypeIsDisabled() {
			return this.getFuelTypes.length === 0;
		},
		versionIsDisabled() {
			return this.getVersions.length === 0;
		},
		presetData() {
			return this.$cloneDeep(this.carPreset);
		},
		isLoading() {
			return this.getVersions.length === 0 && this.firstLoad;
		},
	},
	created() {
		this.damageComponentKey = 1;
	},
	methods: {
		...mapActions({
			getWizardYears: GET_WIZARD_YEARS,
			getWizardData: GET_WIZARD_DATA,
			setCityList: SET_CITY_LIST,
			resetState: RESET_STATE,
			fetchColors: FETCH_COLORS,
		}),
		initCarInfo() {
			return new Promise((resolve) => {
				this.$nextTick(() => {
					this.fetchColors();

					this.carInfo = {
						key: this.presetData.carId,
						year: this.presetData.carYear,
						brand: { id: this.presetData.carCbrId },
						model: { id: this.presetData.carCmdId },
						bodyType: { id: this.presetData.carCbtId },
						transmissionType: { id: this.presetData.carCtrId },
						fuelType: { id: this.presetData.carCfuId },
						version: { id: this.presetData.carCvrId },
						kilometers: this.presetData.carKilometer,
						colorId: this.presetData.carClrId,
						additionalDamageCost: this.presetData.cidAdditionalDamageCost,
						cityId: this.presetData.cicCitId,
					};

					this.setCityList();
					this.getWizardYears();

					this.yearChanged(this.presetData.carYear, true);

					resolve();
				});
			});
		},
		saveCarDetails() {
			const { damageComponent } = this.$refs;
			const damageData = damageComponent.damage;

			const payload = {
				...damageData,
				...this.carInfo,
			};
			this.$emit('saveCarDetails', payload, () => {
				this.editDialog = false;
			});
		},
		cancel() {
			this.editDialog = false;
			this.damageComponentKey += 1;
		},
		comboboxClick() {
			this.firstLoad = false;
		},
		yearChanged(year) {
			const payload = {
				year,
			};
			this.getWizardData({ payload, fillArrayField: 'brands' }).then(() => {
				this.brandChanged();
			});
		},
		brandChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
			};

			this.getWizardData({ payload, fillArrayField: 'models' }).then(() => {
				if (!this.getModels.some((x) => x.id === this.carInfo.model.id)) {
					this.carInfo.model = null;
					return;
				}

				this.modelChanged();
			});
		},
		modelChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
			};
			this.getWizardData({ payload, fillArrayField: 'bodyTypes' }).then(() => {
				if (!this.getBodyTypes.some((x) => x.id === this.carInfo.bodyType.id)) {
					this.carInfo.bodyType = null;
					return;
				}

				this.bodyTypeChanged();
			});
		},
		bodyTypeChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
				bodyTypeId: this.carInfo.bodyType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'transmissionTypes' }).then(() => {
				if (!this.getTransmissionTypes.some((x) => x.id === this.carInfo.transmissionType.id)) {
					this.carInfo.transmissionType = null;
					return;
				}

				this.transmissionTypeChanged();
			});
		},
		transmissionTypeChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
				bodyTypeId: this.carInfo.bodyType.id,
				transmissionTypeId: this.carInfo.transmissionType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'fuelTypes' }).then(() => {
				if (!this.getFuelTypes.some((x) => x.id === this.carInfo.fuelType.id)) {
					this.carInfo.fuelType = null;
					return;
				}

				this.fuelTypeChanged();
			});
		},
		fuelTypeChanged() {
			const payload = {
				year: this.carInfo.year,
				brandId: this.carInfo.brand.id,
				modelId: this.carInfo.model.id,
				bodyTypeId: this.carInfo.bodyType.id,
				transmissionTypeId: this.carInfo.transmissionType.id,
				fuelTypeId: this.carInfo.fuelType.id,
			};
			this.getWizardData({ payload, fillArrayField: 'versions' }).then(() => {
				if (!this.getVersions.some((x) => x.id === this.carInfo.version.id)) {
					this.carInfo.version = null;
				}
			});
		},
	},
	watch: {
		editDialog: {
			handler(isShow) {
				if (isShow) {
					this.firstLoad = true;
					this.resetState().then(() => {
						this.initCarInfo().then(() => {
							this.initialized = true;
						});
					});
				}
			},
		},
	},
};
</script>

<style lang="scss" scoped>
.v-subheader {
	padding: 0px 10px;
	height: 30px;
}
</style>
