<template>
	<v-main class="pa-0" v-if="carPreset">
		<v-card elevation="0">
			<v-card-text>
				<v-row>
					<v-col cols="12" md="6">
						<div class="d-flex justify-start align-start text-subtitle-1 font-weight-bold">
							<div v-if="carPreset.carTrackingNumber">
								Talep ID :
								<v-chip color="primary mx-2" small> #{{ carPreset.carTrackingNumber }}</v-chip>
							</div>
							<div v-if="carPreset.carDate">
								Talep Tarihi :
								<v-chip color="primary mx-2" small> {{ formatRequest(carPreset.carDate) }}</v-chip>
							</div>
							<div v-if="supervisorSelectorIsShow">
								Temsilci :
								<v-menu offset-y rounded="b-xl" z-index="9999" max-width="175">
									<template v-slot:activator="{ on, attrs }">
										<v-chip
											:class="{ 'rounded-b-0': isAuthUser }"
											color="primary mx-2"
											small
											v-bind="attrs"
											v-on="on"
											style="max-width:175px"
										>
											<template v-slot:default>
												<div class="text-truncate" style="max-width:175px;">
													<v-icon size="10" class="mb-1 mr-2" v-text="'fas fa-user'"></v-icon>
													{{ supervisorName }}
												</div>
												<v-icon v-if="isAuthUser" class="ml-2" v-text="'fas fa-chevron-down'" x-small></v-icon>
											</template>

											></v-chip
										>
									</template>
									<v-list dense max-height="150" ma v-if="isAuthUser">
										<v-list-item
											dense
											v-for="(item, index) in supervisors"
											:key="index"
											@click="setSupervisor(item)"
											active-class="red--text"
											:class="item.id === selectedSupervisor ? 'grey lighten-4' : ''"
										>
											<v-list-item-content>
												<v-list-item-title
													class="text-truncate"
													style="max-width:175px;"
													:class="item.id === selectedSupervisor ? 'primary--text font-weight-bold' : ''"
													>{{ item.name }}
												</v-list-item-title>
												<v-list-item-subtitle>
													{{ item.email }}
												</v-list-item-subtitle>
											</v-list-item-content>
										</v-list-item>
									</v-list>
								</v-menu>
							</div>
						</div>
					</v-col>
					<v-col cols="12" md="6" v-if="isAuthUser" class="d-flex justify-end">
						<v-btn
							class="text-capitalize"
							color="grey lighten-2"
							depressed
							small
							@click="$router.push({ name: 'requests' })"
						>
							<v-icon v-text="'fas fa-chevron-left'" small left></v-icon>
							Talep listesine dön
						</v-btn>
						<div class="mx-1" v-if="!isCompleted && !isCancelled"></div>
						<cancel-request-dialog
							v-if="!isCompleted && !isCancelled"
							:cancel-status="cancelStatus"
							@cancel-request="cancelRequest"
						></cancel-request-dialog>
						<!-- <v-btn
							class="text-capitalize"
							outlined
							color="red"
							small
							v-if="!isCompleted && !isCancelled"
							@click="cancel"
							>Talebi iptal et</v-btn
						> -->
						<div class="mx-1"></div>
						<appointment @created="appointmentCreated" v-if="appointmentIsShow" />
						<div class="mx-1"></div>
						<v-btn
							class="text-capitalize"
							shaped
							color="teal"
							dark
							small
							elevation="0"
							v-if="stepButtonText"
							@click.prevent="nextStepFunc"
							>{{ stepButtonText }}</v-btn
						>
					</v-col>
				</v-row>

				<v-spacer class="my-5"></v-spacer>
				<v-stepper class="request-stepper elevation-0 transparent" alt-labels v-model="carPreset.carCurrentWzsId">
					<v-stepper-header class="d-flex stepper-header elevation-0 text-subtitle-1">
						<v-stepper-step complete-icon="far fa-eye" complete :step="sellStep.PRE_EXAMINATION">
							Ön İnceleme
							<div class="step-arrow" v-if="carPreset.carCurrentWzsId === sellStep.PRE_EXAMINATION">
								<v-icon color="teal" v-text="'fas fa-chevron-right'"></v-icon>
							</div>
						</v-stepper-step>
						<v-divider> </v-divider>
						<v-stepper-step complete-icon="fa fa-calendar-alt" complete :step="sellStep.SCHEDULED">
							Randevu
							<div class="step-arrow" v-if="carPreset.carCurrentWzsId === sellStep.SCHEDULED">
								<v-icon color="teal" v-text="'fas fa-chevron-right'"></v-icon>
							</div>
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step complete-icon="fas fa-car-crash" complete :step="sellStep.EXPERTISE">
							Ekspertiz
							<div class="step-arrow" v-if="carPreset.carCurrentWzsId === sellStep.EXPERTISE">
								<v-icon color="teal" v-text="'fas fa-chevron-right'"></v-icon>
							</div>
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step complete-icon="fas fa-turkish-lira-sign" complete :step="sellStep.PAYMENT">
							Ödeme
							<div class="step-arrow" v-if="carPreset.carCurrentWzsId === sellStep.PAYMENT">
								<v-icon color="teal" v-text="'fas fa-chevron-right'"></v-icon>
							</div>
						</v-stepper-step>
						<v-divider></v-divider>
						<v-stepper-step complete-icon="fas fa-thumbs-up" complete :step="sellStep.COMPLETED" v-if="!isCancelled">
							Tamamlandı
						</v-stepper-step>
						<v-stepper-step complete-icon="fas fa-times" class="cancelled" complete :step="sellStep.CANCELLED" v-else>
							İptal Edildi
						</v-stepper-step>
					</v-stepper-header>
					<v-spacer class="my-5"></v-spacer>
					<top-detail />
				</v-stepper>
				<v-spacer class="my-5"></v-spacer>
				<v-tabs
					fixed-tabs
					background-color="teal"
					dark
					v-model="tab"
					slider-color="teal darken-4"
					color="white"
					active-class="teal darken-3 font-weight-bold"
				>
					<v-tab href="#damage-tab"> <v-icon v-text="'fas fa-car-crash'" small left></v-icon> Hasar ve Tramer </v-tab>
					<v-tab href="#detail-tab" v-if="isAuthUser"
						><v-icon v-text="'fas fa-info'" small left></v-icon> Detay Bilgileri</v-tab
					>
					<v-tab href="#files-tab" v-if="isAuthUser && filesIsShow"
						><v-icon v-text="'fas fa-file'" small left></v-icon> Dosyalar</v-tab
					>
				</v-tabs>
				<v-tabs-items v-model="tab">
					<v-tab-item :value="'damage-tab'">
						<damage />
					</v-tab-item>
					<v-tab-item :value="'detail-tab'" v-if="isAuthUser">
						<detail-information />
						<related-car :related-cars="relatedCars" />
					</v-tab-item>
					<v-tab-item :value="'files-tab'" v-if="isAuthUser && filesIsShow">
						<documents />
					</v-tab-item>
				</v-tabs-items>
				<v-spacer class="my-5"></v-spacer>
				<comments @commentCreated="commentCreated" v-if="isAuthUser" />
			</v-card-text>
		</v-card>
	</v-main>
</template>

<script>
import { sellStep } from '@/common/enums';
import { mapActions, mapGetters } from 'vuex';
import {
	FETCH_CAR_PRESET,
	FETCH_COMMENTS,
	FETCH_RELATED_CARS,
	SET_EXPERTISE,
	SET_PAYMENT,
	SET_COMPLETED,
	SET_CANCELLED,
	SET_PRE_EXAMINATION,
	ASSIGN_TO_SUPERVISOR,
} from '@/store/modules/carInfo.module';
import { FETCH_COLORS } from '@/store/modules/carWizard.module';
import { FETCH_SUPERVISORS } from '@/store/modules/company.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import TopDetail from '@/components/backoffice/CarInfoDetail/TopDetail.vue';
import Damage from '@/components/backoffice/CarInfoDetail/Damage.vue';
import DetailInformation from '@/components/backoffice/CarInfoDetail/DetailInformation.vue';
import Documents from '@/components/backoffice/CarInfoDetail/Documents.vue';
import Comments from '@/components/backoffice/CarInfoDetail/Comments.vue';
import Appointment from '@/components/backoffice/CarInfoDetail/Appointment.vue';
import CancelRequestDialog from '@/components/backoffice/CarInfoDetail/CancelRequestDialog.vue';
import RelatedCar from '@/components/backoffice/CarInfoDetail/RelatedCar.vue';

export default {
	components: {
		TopDetail,
		Damage,
		DetailInformation,
		Documents,
		Comments,
		Appointment,
		CancelRequestDialog,
		RelatedCar,
	},
	name: 'CarInfoDetail',
	data() {
		return {
			sellStep,
			tab: null,
			messageDialog: false,
			carId: null,
			selectedSupervisor: null,
		};
	},
	computed: {
		...mapGetters([
			'carPreset',
			'isAuthUser',
			'supervisors',
			'supervisorId',
			'getColors',
			'cancelStatus',
			'relatedCars',
		]),
		filesIsShow() {
			return this.carPreset.carCurrentWzsId >= sellStep.EXPERTISE;
		},
		appointmentIsShow() {
			return this.carPreset.carCurrentWzsId <= sellStep.SCHEDULED;
		},
		supervisorSelectorIsShow() {
			if (this.isAuthUser) return true;

			return this.selectedSupervisor !== null && this.selectedSupervisor > 0;
		},
		stepButtonText() {
			let buttonText = null;
			switch (this.carPreset.carCurrentWzsId) {
				case sellStep.EXPERTISE:
					buttonText = 'Ödeme Adımı';
					break;
				case sellStep.PAYMENT:
					buttonText = 'Tamamla';
					break;
				case sellStep.SCHEDULED:
					buttonText = 'Ekspertiz Adımı';
					break;
				case sellStep.CANCELLED:
					buttonText = 'Yeniden Ön inceleme';
					break;
				default:
					break;
			}
			return buttonText;
		},
		isExpertise() {
			return this.carPreset.carCurrentWzsId === sellStep.EXPERTISE;
		},

		isCompleted() {
			return this.carPreset.carCurrentWzsId === sellStep.COMPLETED;
		},
		isCancelled() {
			return this.carPreset.carCurrentWzsId === sellStep.CANCELLED;
		},
		supervisorName() {
			return this.supervisors?.find((spv) => spv.id === this.supervisorId)?.name.trim() ?? 'Temsilci Ata';
		},
	},
	created() {
		this.$nextTick(() => {
			if (this.getColors.length === 0) this.fetchColors();

			this.fetchCar();
		});
	},
	methods: {
		...mapActions({
			fetchColors: FETCH_COLORS,
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			fetchCarPreset: FETCH_CAR_PRESET,
			fetchComments: FETCH_COMMENTS,
			setExpertise: SET_EXPERTISE,
			setPayment: SET_PAYMENT,
			setCompleted: SET_COMPLETED,
			setCancelled: SET_CANCELLED,
			setPreExamination: SET_PRE_EXAMINATION,
			fetchSupervisors: FETCH_SUPERVISORS,
			assignToSupervisor: ASSIGN_TO_SUPERVISOR,
			fetchRelatedCars: FETCH_RELATED_CARS,
		}),
		fetchCar() {
			this.carId = this.$route.params.id;

			this.fetchCarPreset(this.carId).then(() => {
				this.selectedSupervisor = this.supervisorId;
				this.fetchSupervisors();
				this.openExpertiseTab();
				this.fetchComments(this.carId);
				this.fetchRelatedCars(this.carPreset);
			});
		},
		setSupervisor(spv) {
			if (spv.id === this.selectedSupervisor || !this.isAuthUser) return;
			this.setMessageDialog({
				messageType: 'info',
				subTitle: 'Temsilci ataması!',
				text: `<p>Bu talebi ${spv.name} isimli temsilciye atamak istediğinizden emin misiniz?</p>`,
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () => {
							const payload = {
								key: this.carId,
								userId: spv.id,
							};
							this.assignToSupervisor(payload)
								.then(() => {
									// this.selectedSupervisor = spv.id;
									// this.fetchComments(this.carId);
									this.fetchCar();
									this.disposeMessageDialog();
								})
								.finally(() => {
									this.disposeMessageDialog();
								});
						},
					},
				],
			});
		},
		formatRequest(date) {
			return this.$moment
				.utc(date)
				.local()
				.format('DD-MM-YYYY HH:mm');
		},
		commentCreated() {
			this.setMessageDialog({
				messageType: 'info',
				subTitle: 'Başarılı!',
				text: 'Yorumunuz başarılı bir şekilde kaydedilmiştir.',
			});
		},
		openExpertiseTab() {
			if (!this.isExpertise) return;
			this.tab = 'files-tab';
		},
		appointmentCreated() {
			this.setMessageDialog({
				messageType: 'info',
				subTitle: 'Başarılı!',
				text:
					'Randevu bilgisi sms olarak gönderildi<br /> Yeni bir dosya ile çalışmak için talep sayfasına dönmek ister misiniz?',
				closeButtonText: 'Burada kalacağım',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () => {
							this.disposeMessageDialog();
							this.$router.push({
								name: 'requests',
								params: { id: this.carId },
							});
						},
					},
				],
			});
			this.openExpertiseTab();
			this.fetchComments(this.carId);
		},
		expertise(carId) {
			this.setExpertise(carId).then(() => {
				this.disposeMessageDialog();
				this.openExpertiseTab();
			});
		},
		payment(carId) {
			this.setPayment(carId).then(() => {
				this.disposeMessageDialog();
			});
		},
		complete(carId) {
			this.setCompleted(carId).then(() => {
				this.disposeMessageDialog();
			});
		},
		// cancel() {
		// 	this.setMessageDialog({
		// 		messageType: 'warning',
		// 		subTitle: 'Emin misiniz?',
		// 		text: 'Bu talebi iptal etmek istediğinizden emin misiniz?',
		// 		closeButtonText: 'Vazgeç',
		// 		buttons: [
		// 			{
		// 				text: 'Evet',
		// 				color: 'teal',
		// 				action: () => this.cancelRequest(this.carId),
		// 			},
		// 		],
		// 	});
		// },
		cancelRequest(reasonId, cb) {
			const payload = {
				key: this.carId,
				reasonId,
			};
			this.setCancelled(payload).then(() => {
				cb();
			});
		},
		rePreexamination(carId) {
			this.setPreExamination(carId).then(() => {
				this.disposeMessageDialog();
			});
		},
		nextStepFunc() {
			switch (this.carPreset.carCurrentWzsId) {
				case sellStep.EXPERTISE:
					this.setMessageDialog({
						messageType: 'warning',
						subTitle: 'Emin misiniz?',
						text: 'Ekspertiz işlemlerini tamamlayıp ödeme adımına geçmek istediğinizden emin misiniz?',
						closeButtonText: 'Vazgeç',
						buttons: [
							{
								text: 'Evet',
								color: 'teal',
								action: () => this.payment(this.carId),
							},
						],
					});
					break;
				case sellStep.PAYMENT:
					this.setMessageDialog({
						messageType: 'warning',
						subTitle: 'Emin misiniz?',
						text: 'Ödeme işlemlerini tamamlayıp bu kayıdı kapatmak istediğinizden emin misiniz?',
						closeButtonText: 'Vazgeç',
						buttons: [
							{
								text: 'Evet',
								color: 'teal',
								action: () => this.complete(this.carId),
							},
						],
					});
					break;
				case sellStep.SCHEDULED:
					this.setMessageDialog({
						messageType: 'warning',
						subTitle: 'Emin misiniz?',
						text: 'Ekspertiz adımına geçmek istediğinizden emin misiniz?',
						closeButtonText: 'Vazgeç',
						buttons: [
							{
								text: 'Evet',
								color: 'teal',
								action: () => this.expertise(this.carId),
							},
						],
					});

					break;
				case sellStep.CANCELLED:
					this.setMessageDialog({
						messageType: 'warning',
						subTitle: 'Emin misiniz?',
						text: 'Yeniden ön inceleme adımına gitmek istediğinizden emin misiniz?',
						closeButtonText: 'Vazgeç',
						buttons: [
							{
								text: 'Evet',
								color: 'teal',
								action: () => this.rePreexamination(this.carId),
							},
						],
					});
					break;
				default:
					break;
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.stepper-header {
	.v-divider {
		margin: 30px -50px 0 !important;
	}
}
::v-deep {
	.request-stepper {
		&.v-stepper {
			.step-arrow {
				position: absolute;
				top: 18px;
				right: 0px;
			}

			.v-stepper__step {
				&--editable {
					&:hover {
						background: none !important;
					}
					&:active {
						background: none !important;
					}
					&:focus {
						background: none !important;
					}
					&:focus-visible {
						background: none !important;
					}
				}
				padding: 0px;

				&.cancelled {
					.v-stepper__step__step {
						background-color: #ff0015 !important;
						border-color: #ffcfd3 !important;
						.v-icon {
							color: #f1f1f1 !important;
						}
					}
				}

				&--inactive {
					.v-stepper__step__step {
						border-color: rgb(219, 219, 219) !important;
						background-color: transparent !important;
					}
				}

				&--active {
					.v-stepper__step__step {
						background-color: #009688 !important;
						border-color: #00695c !important;

						.v-icon {
							color: #fff !important;
						}
					}
					.v-stepper__label {
						text-shadow: none !important;
						color: #00695c !important;
						font-weight: bold;
					}
				}

				&__step {
					width: 60px;
					height: 60px;
					background-color: #b2dfdb !important;
					border: 4px solid !important;
					border-color: #00695c !important;

					.v-icon {
						color: #00695c;
						font-size: 23px;
					}
				}
			}
		}
	}
}
</style>
