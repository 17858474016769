<template>
	<v-card flat v-if="isShow">
		<v-card-title>İlgili Talepler </v-card-title>
		<v-card-text>
			<v-simple-table dense fixed-header height="300px">
				<template v-slot:default>
					<thead>
						<tr>
							<th>
								Talep Tarihi
							</th>
							<th class="text-center">
								Talep Numarası
							</th>
							<th>
								Durumu
							</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in relatedCarList" :key="item.name" @click.stop="goToRequest(item)">
							<td>{{ item.date }}</td>
							<td class="text-center font-weight-bold">
								<v-chip
									x-small
									class="white--text"
									:color="item.status === 'İptal' ? 'red' : 'green'"
									v-if="item.trackingNo !== ''"
								>
									{{ item.trackingNo }}</v-chip
								>
							</td>
							<td>{{ item.status }}</td>
						</tr>
					</tbody>
				</template>
			</v-simple-table>
		</v-card-text>
	</v-card>
</template>

<script>
export default {
	name: 'RelatedCar',
	props: {
		relatedCars: {
			type: Array,
			default: () => [],
			required: true,
		},
	},
	computed: {
		relatedCarList() {
			return this.relatedCars;
		},
		isShow() {
			return this.relatedCarList.length > 0;
		},
	},
	methods: {
		goToRequest(car) {
			const route = this.$router.resolve({ name: 'carInfoDetails', params: { id: car.id } });
			window.open(route.href, '_blank');
		},
	},
};
</script>

<style lang="scss" scoped>
tbody tr {
	cursor: pointer;
	&:nth-of-type(odd) {
		/* 'teal lighten-5' basides on material design color */
		background-color: #f8f8f8;
	}
}
</style>
