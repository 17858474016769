<template>
	<div class="d-flex flex-column">
		<v-overlay :value="mustUploadOverlay" opacity="0.85">
			<div class="text-center">
				<p>Dosyalar yükleniyor...</p>
				<v-progress-circular indeterminate size="64"></v-progress-circular>
			</div>
		</v-overlay>
		<v-card elevation="0" v-if="mustFileEditable">
			<v-card-text>
				<v-row no-gutters align="end">
					<v-col cols="12" md="3" class="mr-5">
						<v-file-input
							color="teal"
							hide-details="auto"
							outlined
							ref="licence"
							dense
							label="Ruhsat belgesi seçiniz"
							prepend-icon=""
							clear-icon=""
							class="must-file pr-0"
							accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp"
							v-model="licence"
						>
							<template v-slot:append>
								<v-icon small class="mr-5" @click="clearLicence" v-if="licence != null">fas fa-times</v-icon>
								<v-btn
									color="teal"
									class="text-capitalize rounded-r-sm"
									height="40"
									outlined
									tile
									dark
									elevation="0"
									@click="licenceClick"
									>Dosya seç
								</v-btn>
							</template>
						</v-file-input>
					</v-col>
					<v-col cols="12" md="3" class="mr-3">
						<v-file-input
							color="teal"
							hide-details="auto"
							outlined
							ref="expertise"
							dense
							label="Ekspertiz belgesi seçiniz"
							prepend-icon=""
							clear-icon=""
							class="must-file pr-0"
							accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp"
							v-model="expertise"
						>
							<template v-slot:append>
								<v-icon small class="mr-5" @click="clearExpertise" v-if="expertise != null">fas fa-times</v-icon>
								<v-btn
									color="teal"
									class="text-capitalize rounded-r-sm"
									height="40"
									outlined
									tile
									dark
									elevation="0"
									@click="expertiseClick"
									>Dosya seç
								</v-btn>
							</template>
						</v-file-input></v-col
					>
					<v-col cols="12" md="1">
						<v-btn
							height="40"
							class="text-capitalize"
							color="teal"
							dark
							small
							@click="uploadExpertiseAndLicenceFile"
							upload="uploadFileLoader"
						>
							<template v-slot:loader>
								<span>Dosyalar yükleniyor...</span>
							</template>
							Seçili Dosyaları Yükle</v-btn
						>
					</v-col>
				</v-row>
			</v-card-text>
		</v-card>
		<drop-zone-upload
			@fileUpload="uploadDropZoneFiles"
			@fileDelete="fileDelete"
			:filesPreset="files"
			:options="dropZoneOptions"
			:editable="filesEditable"
			:dropZoneUploader.sync="dropZoneUploader"
			v-if="filesIsShow"
		/>
		<v-card elevation="0" v-if="filesIsShow">
			<v-card-title> Kayıtlı dosyalar </v-card-title>
			<v-card-text>
				<v-simple-table dense class="elevation-0" v-if="files.length > 0">
					<thead>
						<tr>
							<th class="text-left">Ekleyen</th>
							<th class="text-left">Dosya Adı</th>
							<th class="text-left">Kayıt Tarihi</th>
							<th class="text-left">İşlemler</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="item in files" :key="item.fileId">
							<td>{{ item.user }}</td>
							<td>{{ item.fileName }}</td>
							<td>{{ $moment(item.time).format('DD-MM-YYYY HH:mm') }}</td>
							<td>
								<v-btn icon small color="teal" :href="item.filePath">
									<v-icon x-small>fas fa-angle-double-down</v-icon>
								</v-btn>
								<v-btn icon small color="red" v-if="filesEditable">
									<v-icon x-small @click="fileDelete(item)">far fa-trash-alt</v-icon>
								</v-btn>
							</td>
						</tr>
					</tbody>
				</v-simple-table>
				<div class="text-center" v-else>Henüz dosya eklenmedi.</div>
			</v-card-text>
		</v-card>
	</div>
</template>

<script>
import { sellStep } from '@/common/enums';
import { mapGetters, mapActions } from 'vuex';
import { UPLOAD_MULTIPLE_FILES, DELETE_FILE_SERVER } from '@/store/modules/carInfo.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import DropZoneUpload from '@/components/_shared/DropZoneUpload.vue';

export default {
	name: 'DocumentsView',
	components: {
		DropZoneUpload,
	},
	data() {
		return {
			dropZoneOptions: {
				label: 'Dosya eklemek için sürükleyiniz ya da tıklayınız.',
				saveButton: {
					label: 'Dosyaları yükle',
					color: 'teal',
				},
			},
			carId: null,
			fileData: [],
			dropZoneUploader: false,
			licence: null,
			expertise: null,
			mustUploadOverlay: false,
		};
	},
	computed: {
		...mapGetters(['files', 'carPreset']),
		filesIsShow() {
			return this.carPreset.carCurrentWzsId >= sellStep.SCHEDULED;
		},
		mustFileEditable() {
			return this.filesIsShow && this.carPreset.carCurrentWzsId < sellStep.PAYMENT;
		},
		filesEditable() {
			return this.filesIsShow && this.carPreset.carCurrentWzsId <= sellStep.PAYMENT;
		},
	},
	created() {
		this.$nextTick(() => {
			this.carId = this.$route.params.id;
		});
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			upladMultipleFiles: UPLOAD_MULTIPLE_FILES,
			deleteFile: DELETE_FILE_SERVER,
		}),
		// downloadFile(item) {
		// 	window.open(item.filePath);
		// },
		checkFileExtension(files) {
			return new Promise((resolve, reject) => {
				const allowedExtensions = /(\.pdf|\.png|\.jpg|\.jpeg|\.gif|\.bmp)$/i;
				const hasIgnoredExt = Array.from(files).some((f) => !allowedExtensions.exec(f.name));
				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}
				resolve();
			});
		},
		licenceClick() {
			this.$refs.licence.$el.querySelector('input[type=file]').click();
		},
		expertiseClick() {
			this.$refs.expertise.$el.querySelector('input[type=file]').click();
		},
		clearLicence() {
			this.$refs.licence?.reset();
			this.licence = null;
		},
		clearExpertise() {
			this.$refs.expertise?.reset();
			this.expertise = null;
		},
		getFile(file) {
			if (!file) return null;
			return new Promise((resolve) => {
				const reader = new FileReader();
				reader.onload = (e) => {
					const array = new Uint8Array(e.target.result);
					const byte = [];
					for (let i = 0; i < array.length; i += 1) {
						byte.push(array[i]);
					}
					resolve({ name: file.name, file: byte });
				};
				reader.readAsArrayBuffer(file);
			});
		},
		async uploadExpertiseAndLicenceFile() {
			this.mustUploadOverlay = true;
			const mustFile = {};
			if (this.licence) mustFile.license = this.licence;
			if (this.expertise) mustFile.expertise = this.expertise;

			this.uploadMustFile(mustFile, () => {
				this.clearLicence();
				this.clearExpertise();
			});
		},
		async uploadMustFile(mustFile, complete) {
			const expertiseFile = await this.getFile(mustFile.expertise);
			const licenseFile = await this.getFile(mustFile.license);
			const payload = { key: this.carId, expertiseFile, licenseFile };
			this.upladMultipleFiles(payload)
				.then(() => {
					this.fileData = [];
					complete();
				})
				.finally(() => {
					this.mustUploadOverlay = false;
				});
		},
		uploadDropZoneFiles(uploadFiles, complete) {
			this.mustUploadOverlay = true;
			this.dropZoneUploader = true;
			const promises = [];
			uploadFiles.forEach((file) => promises.push(this.getFile(file)));

			Promise.all(promises).then((files) => {
				const payload = { key: this.carId, files };
				this.upladMultipleFiles(payload)
					.then(() => {
						complete();
					})
					.finally(() => {
						this.dropZoneUploader = false;
						this.mustUploadOverlay = false;
					});
			});
		},
		fileDelete(data) {
			const payload = { key: this.carId, data };
			this.deleteFile(payload);
		},
	},
};
</script>
<style lang="scss" scoped>
::v-deep {
	.v-subheader {
		padding: 0px 10px;
		height: 30px;
	}
	.must-file {
		.v-input__slot {
			padding-right: 0px !important;
		}
		.v-input__append-inner {
			margin: 0px !important;
		}
	}
}
</style>
