<template>
	<v-card elevation="0">
		<v-card-text @dragover.prevent @drop.prevent>
			<v-container
				class="file-drop-area d-flex justify-center align-center"
				@dragleave="fileDragOut"
				@dragover="fileDragIn"
				@drop="handleFileDrop"
				@click="clickHandler"
			>
				<div class="file-msg">
					<div class="d-flex flex-wrap" v-if="files.length > 0 || filesPreset.length > 0">
						<div
							class="file-list elevation-1 ma-2 pa-2 d-flex flex-column text-center justify-center align-center server-item"
							v-for="(savedItem, index) in sortedFilePreset"
							:key="`server-file-${index}`"
						>
							<div class="d-flex">
								<v-avatar size="15" color="green">
									<v-icon size="7" dark>fas fa-check</v-icon>
								</v-avatar>
							</div>
							<p class="text-truncate" style="width: 120px" :title="savedItem.fileName">{{ savedItem.fileName }}</p>
							<v-spacer class="my-2"></v-spacer>
							<v-img
								max-width="135"
								max-height="100"
								height="100"
								width="135"
								contain
								:lazy-src="`${savedItem.filePath}?time=${$moment().unix()}`"
								:src="`${savedItem.filePath}?time=${$moment().unix()}`"
								v-if="savedItem.typeId === fileType.IMAGE"
							/>
							<v-avatar width="135" height="100" v-else>
								<v-icon size="60">fas fa-file</v-icon>
							</v-avatar>
							<v-spacer class="my-2"></v-spacer>
							<v-btn small icon @click="deleteFileFromServer(savedItem)" title="Sil" v-if="editable">
								<v-icon x-small>fa fa-trash</v-icon>
							</v-btn>
						</div>
						<div
							class="file-list elevation-1 ma-2 pa-2 d-flex flex-column justify-center align-center"
							v-for="(file, index) in sortedFiles"
							:key="`upload-file-${index}`"
						>
							<div class="d-flex">
								<v-avatar size="15" color="grey">
									<v-icon size="7" dark>fas fa-check</v-icon>
								</v-avatar>
							</div>
							<p class="text-truncate" style="width: 120px" :title="file.name">{{ file.name }}</p>
							<v-spacer class="my-2"></v-spacer>
							<image-loader :image="file" />
							<v-spacer class="my-2"></v-spacer>
							<v-btn small icon @click="removeFile(index)" title="Sil">
								<v-icon x-small>fa fa-trash</v-icon>
							</v-btn>
						</div>
					</div>
					<template v-else>
						{{ dropZoneOptions.label }}
					</template>
				</div>
				<input
					class="file-input"
					type="file"
					multiple
					@change="handleFileInput"
					accept=".pdf, .png, .jpg, .jpeg, .gif, .bmp"
				/>
			</v-container>
		</v-card-text>
		<v-card-actions v-if="editable">
			<v-flex md12 class="text-center justify-center align-center">
				<v-btn
					small
					:loading="dropZoneUploader"
					:color="dropZoneOptions.saveButton.color"
					class="text-capitalize"
					:dark="!isUploadButtonDisabled"
					@click="save"
					:disabled="isUploadButtonDisabled"
				>
					<template v-slot:loader>
						<span>Yükleniyor...</span>
					</template>
					{{ dropZoneOptions.saveButton.label }}</v-btn
				>
			</v-flex>
		</v-card-actions>
	</v-card>
</template>

<script>
import { fileType } from '@/common/enums';
import ImageLoader from '@/components/_shared/ImageLoader.vue';
import { mapActions } from 'vuex';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';

export default {
	name: 'DropZoneUpload',
	props: {
		dropZoneUploader: {
			type: Boolean,
			required: true,
			default: false,
		},
		options: {
			type: Object,
			required: false,
			default: () => this.defaultOptions,
		},
		filesPreset: {
			type: Array,
			default: () => [],
		},
		editable: {
			type: Boolean,
		},
	},
	components: {
		ImageLoader,
	},
	data() {
		return {
			fileType,
			files: [],
			defaultOptions: {
				label: 'Choose files or drag and drop files here',
				saveButton: {
					label: 'Save Files',
					color: 'primary',
				},
			},
		};
	},
	computed: {
		dropZoneOptions() {
			const options = { ...this.defaultOptions, ...this.options };
			return options;
		},
		isUploadButtonDisabled() {
			return this.files.length === 0;
		},
		sortedFiles() {
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			const sorted = this.files.sort((a, b) => (a.typeId < b.typeId ? -1 : 1));
			return sorted;
		},
		sortedFilePreset() {
			const presets = this.filesPreset;
			// eslint-disable-next-line vue/no-side-effects-in-computed-properties
			const sorted = presets.sort((a, b) => (a.typeId < b.typeId ? -1 : 1));
			return sorted;
		},
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
		}),
		clickHandler(e) {
			if (!this.editable) {
				e.preventDefault();
			}
		},
		handleFileDrop(e) {
			if (!this.editable) return;
			const droppedFiles = e.dataTransfer.files;
			if (!droppedFiles) return;
			[...droppedFiles].forEach((f) => {
				this.files.push(f);
			});
			this.color = 'grey lighten-3';
		},
		handleFileInput(e) {
			if (!this.editable) return;
			const { files } = e.target;
			this.checkFileExtension(files).then(() => {
				if (!files) return;
				[...files].forEach((f) => {
					this.files.push(f);
				});
			});
		},
		checkFileExtension(files) {
			return new Promise((resolve, reject) => {
				const allowedExtensions = /(\.pdf|\.png|\.jpg|\.jpeg|\.gif|\.bmp)$/i;
				const hasIgnoredExt = Array.from(files).some((f) => !allowedExtensions.exec(f.name));

				if (hasIgnoredExt) {
					reject();
					this.setMessageDialog({
						messageType: 'error',
						subTitle: 'Hatalı!',
						text: 'Geçersiz dosya türü!',
					});
				}

				resolve();
			});
		},
		removeFile(fileKey) {
			this.files.splice(fileKey, 1);
		},
		deleteFileFromServer(data) {
			this.$emit('fileDelete', data);
		},
		fileDragIn() {
			this.color = 'is-active';
		},
		fileDragOut() {
			this.color = '';
		},
		save() {
			this.$emit('fileUpload', this.files, () => {
				this.files = [];
			});
		},
	},
};
</script>

<style lang="scss" scoped>
.file-drop-area {
	z-index: 1;
	position: relative;
	display: flex;
	align-items: center;
	max-width: 100%;
	padding: 25px;
	border: 1px dashed rgba(192, 192, 192, 0.959);
	border-radius: 5px;
	transition: 0.2s;
	min-height: 200px;
	&.is-active {
		background-color: rgba(255, 255, 255, 0.05);
	}

	.file-list {
		position: relative;
		z-index: 999;
	}
	.server-item {
		border: 1px solid #00d9c4fa;
	}
}
.file-msg {
	font-size: small;
	font-weight: 300;
	line-height: 1.4;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
}

.file-input {
	position: absolute;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	cursor: pointer;
	opacity: 0;
	&:focus {
		outline: none;
	}
}
</style>
