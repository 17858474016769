<template>
	<v-card :color="$vuetify.theme.dark ? 'grey darken-4' : 'grey lighten-4'" elevation="10">
		<v-card-text>
			<v-row class="align-center">
				<v-col cols="12" md="1" class="d-flex justify-center align-center">
					<img
						ref="carLogo"
						:src="getCarImage(carPreset.brand)"
						:alt="carPreset.brand"
						@error="imgErrorEvent"
						contain
						width="80px"
						height="80px"
					/>
				</v-col>
				<v-col cols="12" md="8" class="text-subtitle-1">
					<div class="font-weight-bold text-h6">
						{{ carPreset.carYear }} {{ carPreset.brand }} {{ carPreset.model }}
						<car-edit-dialog @saveCarDetails="udpateCar" v-if="isAuthUser && isCarUpdateButtonShow" />
					</div>
					<div>{{ carPreset.bodyType }} {{ carPreset.transmissonType }} {{ carPreset.fuelType }}</div>
					<div>{{ carPreset.version }}</div>
					<div>
						{{ carPreset.carKilometer }} KM

						<v-chip x-small class="mx-1" color="green template-text--text font-weight-bold" v-if="scratchedCount > 0">
							{{ scratchedCount }}Ç
						</v-chip>
						<v-chip x-small class="mx-1" color="primary template-text--text font-weight-bold" v-if="paintedCount > 0"
							>{{ paintedCount }}B
						</v-chip>
						<v-chip x-small class="mx-1" color="red template-text--text font-weight-bold" v-if="replacedCount > 0">
							{{ replacedCount }}D
						</v-chip>
					</div>
				</v-col>
				<v-col cols="12" md="3" class="text-right text-subtitle-1">
					<div class="font-weight-bold text-h6">Teklif fiyatı</div>
					<div class="text-h4 teal--text">
						<v-menu
							v-model="priceDialog"
							:nudge-top="15"
							:offset-y="true"
							:close-on-content-click="false"
							:close-on-click="false"
							top
						>
							<template v-slot:activator="{ on, attrs }">
								<v-btn
									x-small
									color="teal"
									outlined
									class="mx-2 text-capitalize"
									title="Güncelle"
									v-bind="attrs"
									v-on="on"
									v-if="isCarPriceUpdateButtonShow"
								>
									<v-icon class="mr-1" v-text="'fas fa-pen'" size="8"></v-icon>Güncelle
								</v-btn>
							</template>
							<v-card>
								<v-card-text>
									<v-text-field-money
										v-model="newPrice"
										v-bind:label="'Yeni teklifi giriniz.'"
										v-bind:properties="{
											color: 'teal',
											autofocus: true,
										}"
										v-bind:options="{
											locale: 'tr-TR',
											precision: 0,
										}"
										@keydown.native="updatePriceDialog($event)"
									/>
								</v-card-text>
								<v-card-actions>
									<v-spacer></v-spacer>
									<v-btn color="red darken-1" text @click="closePriceEdit" small> Vazgeç </v-btn>
									<v-btn color="teal darken-1" text @click="openUpdatePriceDialog" small> Kaydet </v-btn>
								</v-card-actions>
							</v-card>
						</v-menu>
						<price-history />
					</div>
					<div>SmartIQ Perakende Fiyatı : {{ carPreset.carRetailPrice | tl({ avoidEmptyDecimals: '' }) }}</div>
				</v-col>
			</v-row>
		</v-card-text>
	</v-card>
</template>

<script>
import { sellStep } from '@/common/enums';
import { mapGetters, mapActions } from 'vuex';
import { UPDATE_PRICE, UPDATE_CAR_INFO, FETCH_CAR_PRESET } from '@/store/modules/carInfo.module';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import CarEditDialog from './CarEditDialog.vue';
import PriceHistory from './PriceHistory.vue';

export default {
	name: 'TopDetail',
	components: {
		CarEditDialog,
		PriceHistory,
	},
	data() {
		return {
			priceDialog: false,
			editCarDialog: false,
			newPrice: null,
		};
	},
	computed: {
		...mapGetters(['carPreset', 'isAuthUser']),
		isCompleted() {
			return this.carPreset.carCurrentWzsId === sellStep.COMPLETED;
		},
		isCancelled() {
			return this.carPreset.carCurrentWzsId === sellStep.CANCELLED;
		},
		isCarPriceUpdateButtonShow() {
			return this.isAuthUser && !this.isCompleted && !this.isCancelled;
		},
		isCarUpdateButtonShow() {
			return this.carPreset.carCurrentWzsId <= sellStep.PAYMENT;
		},
		scratchedCount() {
			return this.carPreset.scratchedCount;
		},
		paintedCount() {
			return this.carPreset.paintedCount;
		},
		replacedCount() {
			return this.carPreset.replacedCount;
		},
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			updatePrice: UPDATE_PRICE,
			updateCarInfo: UPDATE_CAR_INFO,
			fetchCarPreset: FETCH_CAR_PRESET,
		}),
		closePriceEdit() {
			this.priceDialog = false;
			this.newPrice = null;
		},
		openUpdatePriceDialog() {
			if (this.newPrice === null) {
				this.setMessageDialog({
					messageType: 'error',
					subTitle: 'Teklif Fiyatı',
					text: 'Lütfen teklif fiyatını giriniz.',
				});
				return;
			}
			this.setMessageDialog({
				messageType: 'warning',
				subTitle: 'Teklif Fiyatı',
				text: 'Teklif fiyatını güncellemek istediğinizden emin misiniz?',
				persistent: true,
				closeButtonText: 'Vazgeç',
				buttons: [
					{
						text: 'Evet',
						color: 'teal',
						action: () => this.updatePriceConfirm(),
					},
				],
			});
		},
		updatePriceDialog($event) {
			if ($event.keyCode === 13) this.updatePriceConfirm();
		},
		updatePriceConfirm() {
			const payload = { key: this.carPreset.carId, price: this.newPrice };
			this.updatePrice(payload).then(() => {
				this.disposeMessageDialog();
				this.newPrice = null;
				this.priceDialog = false;
			});
		},
		udpateCar(payload, complete) {
			this.updateCarInfo(payload).then(() => {
				this.fetchCarPreset(this.carPreset.carId).then(() => {
					this.editCarDialog = false;
					this.setMessageDialog({
						messageType: 'info',
						subTitle: 'Başarılı',
						text: 'Araç detayları başarılı şekilde güncellenmiştir.',
						defaultCloseButton: false,
						buttons: [
							{
								text: 'Tamam',
								color: 'teal',
								action: () => {
									this.disposeMessageDialog();
									if (complete) complete();
								},
							},
						],
					});
				});
			});
		},
		getCarImage(brandName) {
			if (brandName === null) return '';
			return `https://mars-contents.fra1.digitaloceanspaces.com/qa/brand_logo/${brandName}.png`;
		},
		imgErrorEvent(e) {
			e.target.src = 'https://via.placeholder.com/80';
		},
	},
};
</script>

<style></style>
