<template>
	<v-dialog v-model="dialog.show" width="800">
		<template v-slot:activator="{ attrs }">
			<v-btn
				color="teal lighten-3"
				class="mx-1 font-weight-bold"
				depressed
				x-small
				@click="getTramers"
				v-bind="attrs"
				dark
				:loading="loading"
			>
				<v-icon v-text="'fas fa-search'" size="8" class="mr-2"></v-icon>
				SORGULA
				<template v-slot:loader>
					<span class="custom-loader">
						<v-icon dark size="10" v-text="'fas fa-circle-notch fa-spin'"></v-icon>
					</span>
				</template>
			</v-btn>
		</template>

		<v-card>
			<v-card-title>
				Tramer Kayıtları
				<span class="ml-1" v-if="tramerDetails">/ {{ tramerDetails.date }}</span>
			</v-card-title>

			<v-card-text>
				<div v-if="tramerDetails">
					<v-row class="my-3">
						<v-col cols="12" md="2" class="font-weight-bold">KM</v-col>
						<v-col cols="12" md="2">{{ tramerDetails.detail.partChangeInfo.kilometer }}</v-col>
						<v-col cols="12" md="2" class="font-weight-bold">Tramer Tutarı</v-col>
						<v-col cols="12" md="2">{{
							tramerDetails.detail.partChangeInfo.totalVehiclePartPrice
								| currency({
									fractionCount: 2,
									symbol: tramerDetails.detail.partChangeInfo.vehiclePartCurrency,
									symbolPosition: '',
								})
						}}</v-col>
						<v-col cols="12" md="2" class="font-weight-bold">İşçilik Tutarı</v-col>
						<v-col cols="12" md="2">{{
							tramerDetails.detail.partChangeInfo.totalWorkmanshipPrice
								| currency({
									fractionCount: 2,
									symbol: tramerDetails.detail.partChangeInfo.workmanshipCurrency,
									symbolPosition: '',
								})
						}}</v-col>
					</v-row>
					<v-simple-table dense>
						<template v-slot:default>
							<thead>
								<tr>
									<th class="text-left">Adet</th>
									<th class="text-center">Parça</th>
									<th class="text-center">Tip</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(damage, index) in tramerDetails.detail.damageDetail" :key="`damage-detail-${index}`">
									<td class="text-left">{{ damage.partCount }}</td>
									<td class="text-center">{{ damage.partName }}</td>
									<td class="text-center">{{ damage.partType }}</td>
								</tr>
							</tbody>
						</template>
					</v-simple-table>
				</div>
				<v-simple-table dense v-else>
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Tarih</th>
								<th class="text-left">Tipi</th>
								<th class="text-center">Değişen</th>
								<th class="text-right">Hasar Değeri</th>
								<th class="text-center">Ağır Hasar</th>
								<th></th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(tramer, index) in tramers" :key="`tramer-${index}`">
								<td>{{ tramer.damageDate }}</td>
								<td>{{ tramer.damageDefinition }}</td>
								<td class="text-center">{{ hasChangePart(tramer) }}</td>
								<td class="text-right">
									{{
										tramer.damagePrice
											| currency({ fractionCount: 2, symbol: tramer.damageCurrency, symbolPosition: '' })
									}}
								</td>
								<td class="text-center">{{ isHeavilyDamaged(tramer) }}</td>
								<td class="text-right">
									<v-btn @click="showDetail(tramer)" x-small depressed plain v-if="tramer.damageChangePart">
										Detay</v-btn
									>
								</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
			</v-card-text>

			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="grey darken-1" text @click="back" v-if="tramerDetails">
					Geri
				</v-btn>
				<v-btn color="red darken-1" text @click="dialog.show = false">
					Kapat
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import {
	FETCH_TRAMERS,
	FETCH_TRAMER_DETAIL,
	DISPOSE_TRAMERS,
	DISPOSE_TRAMER_DETAIL,
} from '@/store/modules/carInfo.module';

export default {
	name: 'Tramer',
	data() {
		return {
			loading: false,
			dialog: {
				show: false,
			},
		};
	},
	computed: {
		...mapGetters(['tramers', 'tramerDetails']),
	},
	methods: {
		...mapActions({
			disposeTramer: DISPOSE_TRAMERS,
			disposeTramerDetail: DISPOSE_TRAMER_DETAIL,
			fetchTramers: FETCH_TRAMERS,
			fetchTramerDetail: FETCH_TRAMER_DETAIL,
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
		}),
		hasChangePart(tramer) {
			return tramer.damageChangePart ? 'Var' : 'Yok';
		},
		isHeavilyDamaged(tramer) {
			return tramer.isHeavilyDamaged > 0 ? 'Var' : 'Yok';
		},
		getTramers() {
			this.loading = true;

			const payload = { key: this.$route.params.id };
			this.fetchTramers(payload)
				.then(() => {
					this.dialog.show = true;
				})
				.catch((error) => {
					const dialogOptions = {
						messageType: 'error',
						title: 'Hata Oluştu',
						text: '',
					};

					if (error.code) dialogOptions.text = error.message;
					else
						dialogOptions.text = `Tramer sorgusu sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyiniz. </br><b>${error.cause.message}</b>`;

					this.setMessageDialog(dialogOptions);
				})
				.finally(() => {
					this.loading = false;
				});
		},
		showDetail(tramer) {
			const payload = { key: this.$route.params.id, damageInfo: tramer.damageDate };

			this.fetchTramerDetail(payload)
				.then(() => {})
				.catch((error) => {
					const dialogOptions = {
						messageType: 'error',
						title: 'Hata Oluştu',
						text: '',
					};
					if (error.code) dialogOptions.text = error.message;
					else
						dialogOptions.text = `Tramer detay sorgusu sırasında bir hata oluştu. Lütfen daha sonra tekrar deneyiniz. </br><b>${error.cause.message}</b>`;
				});
		},
		back() {
			this.disposeTramerDetail();
		},
	},
	watch: {
		'dialog.show': {
			handler(show) {
				if (!show) this.disposeTramer();
			},
		},
	},
};
</script>

<style lang="scss" scoped></style>
