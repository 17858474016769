<template>
	<v-dialog v-model="dialog" max-width="400" persistent>
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="text-capitalize" outlined color="red" small v-bind="attrs" v-on="on">
				Talebi İptal Et
			</v-btn>
		</template>
		<v-card>
			<v-card-title class="text-h5">
				Talep İptali
			</v-card-title>
			<v-card-text class="text-center pa-10">
				<v-icon v-text="'fas fa-exclamation-circle'" color="orange" size="70"></v-icon>
				<v-spacer class="my-5"></v-spacer>
				<div class="text-h5 font-weight-bold">
					Bu talebi iptal etmek istediğinizden emin misiniz ?
				</div>
				<v-spacer class="my-3"></v-spacer>
				<div>Talebi iptal etmeden önce lütfen iptal sebebinizi belirtiniz.</div>
				<div>
					<v-select
						color="teal"
						label="İptal nedeni"
						:items="cancelStatusTypes"
						item-value="ccrId"
						item-text="ccrName"
						hide-details="auto"
						:error-messages="cancelTypeErrors()"
						v-model="cancelType"
					></v-select>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red" text @click="dialog = false">
					Vazgeç
				</v-btn>
				<v-btn color="teal" text @click="cancel">
					Talebi İptal Et
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { validationMixin } from 'vuelidate';
import { required } from 'vuelidate/lib/validators';

export default {
	name: 'CancelRequestDialog',
	props: {
		cancelStatus: {
			type: Array,
			default: () => [],
			required: true,
		},
	},
	mixins: [validationMixin],
	validations: {
		cancelType: {
			required,
		},
	},
	data() {
		return {
			dialog: false,
			cancelType: null,
		};
	},
	computed: {
		cancelStatusTypes() {
			return this.cancelStatus;
		},
	},
	methods: {
		cancelTypeErrors() {
			const errors = [];
			const { $dirty, required } = this.$v.cancelType;
			if (!$dirty) return errors;
			// eslint-disable-next-line no-unused-expressions
			!required && errors.push('Lütfen bir iptal nedeni seçiniz.');
			return errors;
		},
		cancel() {
			this.$v.$touch();
			if (this.$v.$invalid) return;

			this.$emit('cancel-request', this.cancelType, () => {
				this.dialog = false;
			});
		},
	},
	watch: {
		dialog: {
			handler() {
				this.cancelType = null;
			},
		},
	},
};
</script>

<style></style>
