<template>
	<v-card flat>
		<v-card-title>Yorumlar</v-card-title>
		<v-card-text>
			<template v-if="comments.length > 0">
				<div v-for="(comment, index) in comments" :key="`comment-${index}`">
					<div class="comment elevation-1 pa-5">
						<div class="d-flex align-center">
							<v-avatar size="25" color="grey lighten-1">
								<v-icon x-small dark v-text="'fa fa-user'"> </v-icon>
							</v-avatar>
							<div class="user mx-2">{{ comment.user }}</div>
							<v-spacer></v-spacer>
							{{ formatCommentDate(comment.time) }}
						</div>
						<v-spacer class="my-5"></v-spacer>
						<div class="px-8">
							{{ comment.comment }}
						</div>
					</div>
					<v-spacer class="my-5" v-if="index < comments.length - 1"></v-spacer>
				</div>
			</template>
			<div class="text-center" v-else>
				Henüz yorum eklenmedi.
			</div>
		</v-card-text>
		<v-card-actions class="px-4">
			<v-flex md12 class="text-center">
				<v-textarea
					color="teal"
					counter="500"
					hide-details="auto"
					outlined
					full-width
					rows="2"
					no-resize
					label="Yorumunuzu yazın..."
					v-model="$v.comment.$model"
					:error-messages="commentErrors()"
				></v-textarea>
				<v-spacer class="my-5"></v-spacer>
				<v-btn
					small
					color="teal"
					class="text-capitalize"
					@click="save"
					:dark="!$v.comment.$invalid"
					:disabled="$v.comment.$invalid"
					>Yorumu Kaydet</v-btn
				>
			</v-flex>
		</v-card-actions>
	</v-card>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { SAVE_COMMENT } from '@/store/modules/carInfo.module';
import { validationMixin } from 'vuelidate';
import commentValidationMixin from '@/mixins/validations/backoffice/commentValidationMixin';

export default {
	name: 'Comments',
	mixins: [validationMixin, commentValidationMixin],
	data() {
		return {
			comment: null,
		};
	},
	computed: {
		...mapGetters(['comments']),
	},
	methods: {
		...mapActions({
			saveComment: SAVE_COMMENT,
		}),
		formatCommentDate(date) {
			return this.$moment
				.utc(date)
				.local()
				.format('DD-MM-YYYY HH:mm');
		},
		save() {
			const payload = {
				key: this.$route.params.id,
				comment: this.comment,
			};
			this.saveComment(payload).then(() => {
				this.comment = null;
				this.$v.comment.$reset();
				this.$emit('commentCreated');
			});
		},
	},
};
</script>

<style></style>
