<template>
	<v-dialog v-model="dialog" persistent max-width="750" v-if="carPreset">
		<template v-slot:activator="{ on, attrs }">
			<v-btn class="text-capitalize" outlined color="teal" small v-bind="attrs" v-on="on">{{
				appointmentButtonText
			}}</v-btn>
		</template>
		<v-card>
			<v-card-title>
				<span class="text-h5">Randevu Bilgisi</span>
			</v-card-title>
			<v-card-text>
				<v-alert color="error" dense dark v-if="hasSmsSendError">
					Randevu SMS bilgisi gönderilirken bir hata oluştu. Lütfen tekrar deneyiniz.
				</v-alert>
				<v-row>
					<v-col md="6" sm="12">
						<v-date-picker
							:min="datePickerOptions.min"
							no-title
							color="teal"
							v-model="datePicker"
							full-width
						></v-date-picker>
					</v-col>
					<v-col md="6" sm="12">
						<div class="time-picker">
							<v-btn-toggle color="teal" borderless mandatory group v-model="timePicker" class="d-flex flex-wrap">
								<v-flex md3 v-for="time in times" :key="time">
									<v-btn block depressed class="rounded-0" :value="time">{{ time }}</v-btn>
								</v-flex>
							</v-btn-toggle>
						</div>
					</v-col>
				</v-row>
				<v-row>
					<v-col cols="4" offset="8">
						Temsilci
						<v-select
							:items="supervisors"
							item-color="teal"
							dense
							item-text="name"
							item-value="id"
							color="teal"
							hide-details="auto"
							outlined
							:return-object="false"
							placeholder="Temsilci ata"
							v-model="supervisor"
						></v-select>
					</v-col>
				</v-row>
				<v-row>
					<v-col>
						<v-textarea
							color="teal"
							hide-details="auto"
							outlined
							full-width
							rows="3"
							no-resize
							v-model="comment"
							label="Yorumunuzu yazın..."
						></v-textarea>
					</v-col>
				</v-row>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="red darken-1" text @click="dialog = false" :disabled="sending">
					Vazgeç
				</v-btn>
				<v-btn color="teal darken-1" text @click="create" :loading="sending">
					Kaydet
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { sellStep } from '@/common/enums';
import { mapGetters, mapActions } from 'vuex';
import { CREATE_APPOINTMENT } from '@/store/modules/carInfo.module';

export default {
	name: 'Appointment',
	data() {
		return {
			times: [
				'09:00',
				'09:30',
				'10:00',
				'10:30',
				'11:00',
				'11:30',
				'12:00',
				'12:30',
				'13:00',
				'13:30',
				'14:00',
				'14:30',
				'15:00',
				'15:30',
				'16:00',
				'16:30',
				'17:00',
				'17:30',
				'18:00',
				'18:30',
			],
			dialog: false,
			datePickerOptions: {
				min: this.$moment().format('YYYY-MM-DD'),
			},
			datePicker: this.$moment().format('YYYY-MM-DD'),
			timePicker: null,
			comment: null,
			supervisor: null,
			sending: false,
			smsSendError: false,
		};
	},
	computed: {
		...mapGetters(['carPreset', 'supervisors', 'supervisorId']),
		fullDate() {
			return this.$moment.utc(`${this.datePicker} ${this.timePicker}`);
		},
		appointmentButtonText() {
			return this.carPreset.carCurrentWzsId === sellStep.PRE_EXAMINATION ? 'Randevu Oluştur' : 'Randevu Düzenle';
		},
		hasSmsSendError() {
			return this.smsSendError;
		},
	},
	created() {
		this.$nextTick(() => {
			this.supervisor = this.supervisorId;
		});
	},
	methods: {
		...mapActions({
			createAppointment: CREATE_APPOINTMENT,
		}),
		create() {
			const payload = {
				key: this.$route.params.id,
				scheduledTime: this.fullDate,
				comment: this.comment,
				userId: this.supervisor,
			};

			this.sending = true;

			this.createAppointment(payload)
				.then((sent) => {
					if (!sent) this.smsSendError = true;
					else {
						this.datePicker = this.$moment().format('YYYY-MM-DD');
						this.timePicker = null;
						this.comment = null;
						this.$emit('created');
						this.dialog = false;
						this.smsSendError = false;
					}
				})
				.finally(() => {
					this.sending = false;
				});
		},
	},
};
</script>

<style></style>
