<template>
	<v-card v-if="carPreset">
		<v-card-text>
			<damage-wizard
				:key="`edit-dialog-damage-${damageComponentKey}`"
				:md-size="6"
				:sm-size="6"
				:readonly="true"
				:justify="$vuetify.breakpoint.lgAndUp ? 'justify-start' : 'justify-center'"
				:preset="carPreset"
			/>
			<div class="d-flex flex-row justify-space-around text-subtitle-1">
				<div class="d-flex flex-column">
					<div class="font-weight-bold">
						Tramer
						<tramer v-if="isAuthUser" />
					</div>
					<div>{{ tramer }}</div>
				</div>
				<div class="d-flex flex-column">
					<div class="font-weight-bold">Tramer Tutarı</div>
					<div>{{ carPreset.cidAdditionalDamageCost | tl({ avoidEmptyDecimals: '' }) }}</div>
				</div>
				<div class="d-flex flex-column">
					<div class="font-weight-bold">Plaka</div>
					<div>{{ carPreset.cidPlateNumber }}</div>
				</div>
				<div class="d-flex flex-column">
					<div class="font-weight-bold">Şasi</div>
					<div>{{ chassisNumber }}</div>
				</div>
			</div>
		</v-card-text>
	</v-card>
</template>

<script>
import { mapGetters } from 'vuex';
import DamageWizard from '@/components/enduser/wizard/Damage.vue';
import Tramer from './Tramer.vue';

export default {
	name: 'Damage',
	components: { DamageWizard, Tramer },
	data() {
		return {
			damageComponentKey: 1,
		};
	},
	created() {
		this.damageComponentKey = 1;
	},
	computed: {
		...mapGetters(['isAuthUser', 'carPreset']),
		name() {
			return this.data;
		},
		tramer() {
			return this.carPreset.cidHasAdditionalDamageCost ? 'Var' : 'Yok';
		},
		chassisNumber() {
			return this.carPreset.cidChassisNumber ? this.carPreset.cidChassisNumber : '-';
		},
	},
	watch: {
		carPreset: {
			handler() {
				this.damageComponentKey += 1;
			},
			deep: true,
		},
	},
};
</script>

<style></style>
