<template>
	<v-dialog persistent max-width="800" v-model="priceHistoryDialog" scrollable>
		<template v-slot:activator="{ on, attrs }">
			<span title="Eski Teklifleri incele" v-bind="attrs" v-on="on">
				<v-tooltip top>
					<template v-slot:activator="{ on, attrs }">
						<span v-bind="attrs" v-on="on">
							{{ carPrice }}
						</span>
					</template>
					<span>Geçmiş Teklifleri İncele</span>
				</v-tooltip>
			</span>
		</template>
		<v-card>
			<v-card-title> Geçmiş Teklifler </v-card-title>
			<v-card-text>
				<v-simple-table dense v-if="priceHistory.length > 0">
					<template v-slot:default>
						<thead>
							<tr>
								<th class="text-left">Tarih</th>
								<th class="text-right">Teklif Fiyatı</th>
								<th class="text-right">Kullanıcı</th>
								<th class="text-right">Türü</th>
							</tr>
						</thead>
						<tbody>
							<tr v-for="(ph, index) in priceHistory" :key="`price-history-${index}`">
								<td>{{ $moment(ph.date).format('DD-MM-YYYY HH:mm') }}</td>
								<td class="text-right">{{ ph.price | tl({ avoidEmptyDecimals: '' }) }}</td>
								<td class="text-right">{{ ph.user }}</td>
								<td class="text-right">{{ ph.status }}</td>
							</tr>
						</tbody>
					</template>
				</v-simple-table>
				<div class="text-center" v-else>
					<v-progress-circular size="25" width="1" indeterminate></v-progress-circular>
				</div>
			</v-card-text>
			<v-card-actions>
				<v-spacer></v-spacer>
				<v-btn color="green darken-1" text @click="cancel">
					Tamam
				</v-btn>
			</v-card-actions>
		</v-card>
	</v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { FETCH_PRICE_HISTORY } from '@/store/modules/carInfo.module';
import { validationMixin } from 'vuelidate';
import infoValidationMixin from '@/mixins/validations/carWizard/infoValidationMixin';

export default {
	name: 'PriceHistory',
	mixins: [validationMixin, infoValidationMixin],
	data() {
		return {
			priceHistoryDialog: false,
		};
	},
	computed: {
		...mapGetters(['carPreset', 'priceHistory']),
		carPrice() {
			return this.$root.$options.filters.tl(this.carPreset.carGalleryPrice, {
				avoidEmptyDecimals: '',
			});
		},
	},
	methods: {
		...mapActions({
			fetchPriceHistory: FETCH_PRICE_HISTORY,
		}),
		cancel() {
			this.priceHistoryDialog = false;
		},
		getPriceHistory() {
			setTimeout(() => {
				this.fetchPriceHistory(this.carPreset.carId);
			}, 2000);
		},
	},
	watch: {
		priceHistoryDialog: {
			handler(isShow) {
				if (isShow) this.getPriceHistory();
			},
		},
	},
};
</script>

<style lang="scss" scoped></style>
