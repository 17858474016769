/* eslint-disable no-unused-expressions */
import { required, email, helpers, minLength } from 'vuelidate/lib/validators';

const alphabeticAndSpace = helpers.regex('alphabeticAndSpace', /^[a-zA-Z ğüşöçİĞÜŞÖÇı]+$/);
const phoneCheck = helpers.regex('phoneCheck', /^(\d{3})(\d{3})(\d{2})(\d{2})$/);

const plateCheck = helpers.regex(
	'plateCheck',
	/^(0[1-9]|[1-7][0-9]|8[01])(([A-Z])(\d{4,5})|([A-Z]{2})(\d{3,4})|([A-Z]{3})(\d{2,4}))$/,
);

const detailInformationValidationMixin = {
	validations: {
		contact: {
			name: {
				alphabeticAndSpace,
				required,
			},
			phoneNumber: {
				required,
				phoneCheck,
			},
			email: {
				email,
			},
			ownerName: {
				alphabeticAndSpace,
			},
			ownerPhone: {
				phoneCheck,
			},
			ownerWorkPhone: {
				phoneCheck,
			},
			ownerEMail: {
				email,
			},
			ibanNo: {
				minLength: minLength(24),
			},
			ownerIdentificationNo: {
				minLength: minLength(11),
			},
			plateNumber: {
				required,
				plateCheck,
			},
		},
	},
	methods: {
		// validation methods for this page
		nameErrors() {
			const errors = [];
			const { $dirty, required, alphabeticAndSpace } = this.$v.contact.name;
			if (!$dirty) return errors;
			!required && errors.push('Bu bilgi gereklidir.');
			!alphabeticAndSpace && errors.push(`Lütfen geçerli bir isim soyisim giriniz.`);
			return errors;
		},
		phoneNumberErrors() {
			const errors = [];
			const { $dirty, required, phoneCheck } = this.$v.contact.phoneNumber;

			if (!$dirty) return errors;
			!required && errors.push('Bu bilgi gereklidir.');
			!phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
			return errors;
		},
		emailErrors() {
			const errors = [];
			const { $dirty, email } = this.$v.contact.email;
			if (!$dirty) return errors;
			!email && errors.push('Geçersiz e-posta adresi.');
			return errors;
		},
		ownerNameErrors() {
			const errors = [];
			const { $dirty, alphabeticAndSpace } = this.$v.contact.ownerName;
			if (!$dirty) return errors;
			!alphabeticAndSpace && errors.push(`Lütfen geçerli bir isim soyisim giriniz.`);
			return errors;
		},
		ownerPhoneErrors() {
			const errors = [];
			const { $dirty, phoneCheck } = this.$v.contact.ownerPhone;
			if (!$dirty) return errors;
			!phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
			return errors;
		},
		ownerWorkPhoneErrors() {
			const errors = [];
			const { $dirty, phoneCheck } = this.$v.contact.ownerWorkPhone;
			if (!$dirty) return errors;
			!phoneCheck && errors.push(`Lütfen geçerli bir telefon numarası giriniz.`);
			return errors;
		},
		ownerEMailErrors() {
			const errors = [];
			const { $dirty, email } = this.$v.contact.ownerEMail;
			if (!$dirty) return errors;
			!email && errors.push('Geçersiz e-posta adresi.');
			return errors;
		},
		ibanNoErrors() {
			const errors = [];
			const { $dirty, minLength } = this.$v.contact.ibanNo;
			if (!$dirty) return errors;
			!minLength && errors.push('Geçersiz IBAN numarası.');
			return errors;
		},
		ownerIdentificationNoErrors() {
			const errors = [];
			const { $dirty, minLength } = this.$v.contact.ownerIdentificationNo;
			if (!$dirty) return errors;
			!minLength && errors.push('Geçersiz Kimlik numarası.');
			return errors;
		},
		plateNumberErrors() {
			const errors = [];
			const { $dirty, required, plateCheck } = this.$v.contact.plateNumber;
			if (!$dirty) return errors;
			!required && errors.push('Bu bilgi gereklidir.');
			!plateCheck && errors.push(`Lütfen geçerli bir plaka giriniz.`);
			return errors;
		},
	},
};

export default detailInformationValidationMixin;
