<template>
	<v-card>
		<v-card-text>
			<v-row>
				<v-col cols="12" lg="3" class="primary-contact">
					<v-card>
						<v-card-title>Müşteri Bilgileri</v-card-title>
						<v-card-text>
							<v-row align="center">
								<v-col cols="12">
									<v-text-field
										color="teal"
										outlined
										flat
										dense
										v-model="$v.contact.name.$model"
										label="Adı Soyadı"
										hide-details="auto"
										:error-messages="nameErrors()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col lg="7" cols="12">
									<v-text-field-simplemask
										v-model="$v.contact.phoneNumber.$model"
										v-bind:label="'Cep Telefon Numarası'"
										v-bind:properties="{
											color: 'teal',
											inputmode: 'numeric',
											prefix: '+90',
											placeholder: '(555)-555-55-55',
											dense: true,
											outlined: true,
											errorMessages: phoneNumberErrors(),
											hideDetails: 'auto',
											flat: true,
										}"
										v-bind:options="{
											inputMask: '(###)-###-##-##',
											outputMask: '##########',
											empty: null,
											applyAfter: false,
											alphanumeric: false,
											lowerCase: false,
										}"
									/>
								</v-col>
								<v-col lg="4" cols="12">
									<v-btn
										small
										color="teal"
										class="text-capitalize"
										:dark="!isAddButtonDisabled"
										@click="add"
										:disabled="isAddButtonDisabled"
										>Kara Listeye Ekle</v-btn
									>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<v-text-field
										color="teal"
										outlined
										flat
										dense
										v-model="$v.contact.email.$model"
										label="E-Posta Adresi"
										hide-details="auto"
										:error-messages="emailErrors()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<v-select
										color="teal"
										:items="getCities"
										outlined
										flat
										label="Şehir"
										v-model="contact.citId"
										item-text="citName"
										item-value="citId"
										:return-object="false"
										hide-details="auto"
										dense
									></v-select>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6" class="secondary-contact">
					<v-card>
						<v-card-title>Araç Sahibi Bilgileri</v-card-title>
						<v-card-text>
							<v-row align="center">
								<v-col lg="6" cols="12">
									<v-text-field
										color="teal"
										outlined
										flat
										dense
										v-model="$v.contact.ownerName.$model"
										label="Adı Soyadı"
										hide-details="auto"
										:error-messages="ownerNameErrors()"
									></v-text-field>
								</v-col>
								<v-col lg="6" cols="12">
									<v-text-field-simplemask
										v-model="$v.contact.ownerPhone.$model"
										v-bind:label="'Cep Telefonu Numarası'"
										v-bind:properties="{
											color: 'teal',
											inputmode: 'numeric',
											prefix: '+90',
											placeholder: '(555)-555-55-55',
											dense: true,
											outlined: true,
											class: 'rounded-lg',
											errorMessages: ownerPhoneErrors(),
											hideDetails: 'auto',
											flat: true,
										}"
										v-bind:options="{
											inputMask: '(###)-###-##-##',
											outputMask: '##########',
											empty: null,
											applyAfter: false,
											alphanumeric: true,
											lowerCase: false,
										}"
									/>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col lg="6" cols="12">
									<v-text-field-simplemask
										v-model="$v.contact.ownerWorkPhone.$model"
										v-bind:label="'Ev/İş Telefonu'"
										v-bind:properties="{
											color: 'teal',
											inputmode: 'numeric',
											prefix: '+90',
											placeholder: '(555)-555-55-55',
											dense: true,
											outlined: true,
											class: 'rounded-lg',
											errorMessages: ownerWorkPhoneErrors(),
											hideDetails: 'auto',
											flat: true,
										}"
										v-bind:options="{
											inputMask: '(###)-###-##-##',
											outputMask: '##########',
											empty: null,
											applyAfter: false,
											alphanumeric: true,
											lowerCase: false,
										}"
									/>
								</v-col>
								<v-col lg="6" cols="12">
									<v-text-field
										color="teal"
										outlined
										flat
										dense
										v-model="$v.contact.ownerEMail.$model"
										label="E-Posta Adresi"
										hide-details="auto"
										:error-messages="ownerEMailErrors()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col lg="6" cols="12">
									<v-text-field
										color="teal"
										outlined
										flat
										dense
										v-model="contact.bankName"
										label="Banka Adı"
										hide-details="auto"
									></v-text-field>
								</v-col>
								<v-col lg="6" cols="12">
									<v-text-field-simplemask
										v-model="$v.contact.ibanNo.$model"
										v-bind:label="'IBAN Numarası'"
										v-bind:properties="{
											color: 'teal',
											prefix: 'TR',
											outlined: true,
											flat: true,
											dense: true,
											hideDetails: 'auto',
											errorMessages: ibanNoErrors(),
										}"
										v-bind:options="{
											inputMask: '## #### #### #### #### #### ##',
											outputMask: '##########################',
											empty: null,
											applyAfter: false,
										}"
									/>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col lg="6" cols="12">
									<v-textarea
										color="teal"
										outlined
										flat
										rows="2"
										dense
										v-model="contact.ownerAddress"
										label="Adres"
										hide-details="auto"
										no-resize
									></v-textarea>
								</v-col>
								<v-col lg="6" cols="12">
									<v-text-field-simplemask
										v-model="$v.contact.ownerIdentificationNo.$model"
										v-bind:label="'TC Kimlik No'"
										v-bind:properties="{
											color: 'teal',
											outlined: true,
											maxlength: 11,
											flat: true,
											dense: true,
											hideDetails: 'auto',
											errorMessages: ownerIdentificationNoErrors(),
										}"
										v-bind:options="{
											inputMask: '###########',
											outputMask: '###########',
											empty: null,
											alphanumeric: false,
											applyAfter: false,
										}"
									/>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col lg="6"> </v-col>
								<v-col lg="6" cols="12">
									<v-menu
										ref="menu"
										v-model="birthDatePicker.menu"
										:close-on-content-click="false"
										transition="scale-transition"
										offset-y
										min-width="auto"
									>
										<template v-slot:activator="{ on }">
											<v-text-field
												color="teal"
												outlined
												flat
												dense
												:value="formattedBirthDate"
												hide-details="auto"
												label="Doğum Tarihi"
												prepend-icon=""
												readonly
												v-on="on"
											>
												<template v-slot:prepend-inner>
													<v-icon v-text="'fas fa-calendar-alt'" small class="mt-1 mr-1"></v-icon>
												</template>
											</v-text-field>
										</template>
										<v-date-picker
											color="teal"
											ref="birthDate"
											v-model="contact.ownerBirthDate"
											:max="$moment().subtract(18, 'years').format('YYYY-MM-DD')"
											:min="$moment().subtract(75, 'years').format('YYYY-MM-DD')"
											@change="birthDatePicker.menu = false"
										></v-date-picker>
									</v-menu>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="3">
					<v-card>
						<v-card-title>Araç Bilgileri</v-card-title>
						<v-card-text>
							<v-row align="center">
								<v-col cols="12">
									<v-select
										color="teal"
										label="Araç Rengi"
										outlined
										item-text="value"
										item-value="id"
										hide-details
										flat
										dense
										clearable
										v-model="contact.colorId"
										:items="getColors"
									></v-select>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<v-text-field
										color="teal"
										label="Araç Plakası"
										outlined
										hide-details="auto"
										maxlength="9"
										flat
										dense
										class="plate"
										v-model="$v.contact.plateNumber.$model"
										@beforeinput="checkPlate($event)"
										:error-messages="plateNumberErrors()"
									></v-text-field>
								</v-col>
							</v-row>
							<v-row align="center">
								<v-col cols="12">
									<v-text-field
										color="teal"
										label="Şasi No"
										maxlength="17"
										outlined
										hide-details="auto"
										flat
										dense
										v-model="contact.chassisNumber"
									></v-text-field>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
			<v-row>
				<v-col cols="12" lg="6">
					<v-card>
						<v-card-title>Ödeme Bilgileri</v-card-title>
						<v-card-text>
							<v-row align="center">
								<v-col lg="12" cols="12">
									<v-textarea
										color="teal"
										outlined
										flat
										rows="5"
										dense
										v-model="contact.paymentInfo"
										label="Bu alana ödeme notlarınızı girebilirsiniz."
										hide-details="auto"
										no-resize
									></v-textarea>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
				<v-col cols="12" lg="6">
					<v-card>
						<v-card-title>Takas Talebi</v-card-title>
						<v-card-text>
							<v-row align="center">
								<v-col lg="2" class="hidden-sm-and-down">Takas İstiyorum</v-col>
								<v-col lg="10" cols="12" class="pt-0">
									<v-checkbox
										color="teal"
										v-model="contact.isExchangeRequest"
										label=""
										hide-details="auto"
									></v-checkbox>
								</v-col>
							</v-row>
							<v-row align="center" v-if="isExchangeRequest">
								<v-col lg="12" cols="12">
									<v-textarea
										color="teal"
										hide-details="auto"
										no-resize
										rows="3"
										outlined
										label="Takas İstediğiniz aracı tanımlayın"
										v-model="contact.exchangeDescription"
									></v-textarea>
								</v-col>
							</v-row>
						</v-card-text>
					</v-card>
				</v-col>
			</v-row>
		</v-card-text>
		<v-card-actions>
			<v-flex md12 class="text-right align-center">
				<v-btn
					small
					color="teal"
					class="text-capitalize"
					:dark="!isSaveButtonDisabled"
					@click="save"
					:disabled="isSaveButtonDisabled"
					>Güncelle</v-btn
				>
			</v-flex>
		</v-card-actions>
	</v-card>
</template>

<script>
/* eslint-disable no-unused-expressions */
/* eslint-disable no-return-assign */
import { mapGetters, mapActions } from 'vuex';
import { SET_MESSAGE_DIALOG, DISPOSE_MESSAGE } from '@/store/modules/messageDialog.module';
import { SET_CITY_LIST } from '@/store/modules/carWizard.module';
import { UPDATE_CAR_DETAIL } from '@/store/modules/carInfo.module';
import { BLACK_LIST_ADD } from '@/store/modules/company.module';
import { validationMixin } from 'vuelidate';
import detailInformationValidationMixin from '@/mixins/validations/backoffice/detailInformationValidationMixin';

export default {
	name: 'DetailInformation',
	mixins: [validationMixin, detailInformationValidationMixin],
	data() {
		return {
			birthDatePicker: {
				menu: false,
				activePicker: null,
			},
			contact: {
				phoneNumber: null,
				email: null,
				name: null,
				citId: null,
				ownerPhone: null,
				ownerEMail: null,
				ownerName: null,
				ownerAddress: null,
				bankName: null,
				ibanNo: null,
				ownerIdentificationNo: null,
				ownerWorkPhone: null,
				colorId: null,
				plateNumber: null,
				chassisNumber: null,
				isExchangeRequest: false,
				exchangeDescription: null,
				ownerBirthDate: null,
				paymentInfo: null,
			},
		};
	},
	created() {
		this.$nextTick(() => {
			this.setCityList();
			this.setContactPreset();

			this.$v.$touch();
		});
	},
	computed: {
		...mapGetters(['carPreset', 'getCities', 'getColors', 'getCities']),
		isSaveButtonDisabled() {
			return this.$v.contact.$invalid;
		},
		isAddButtonDisabled() {
			return this.$v.contact.$invalid;
		},
		isExchangeRequest() {
			return this.contact.isExchangeRequest;
		},
		formattedBirthDate() {
			return this.contact.ownerBirthDate ? this.$moment(this.contact.ownerBirthDate).format('DD/MM/YYYY') : null;
		},
	},
	methods: {
		...mapActions({
			setMessageDialog: SET_MESSAGE_DIALOG,
			disposeMessageDialog: DISPOSE_MESSAGE,
			setCityList: SET_CITY_LIST,
			updateCarDetail: UPDATE_CAR_DETAIL,
			addBlackList: BLACK_LIST_ADD,
		}),
		checkPlate($event) {
			if (/[^A-Za-z0-9\b]+/g.test($event.data)) {
				$event.preventDefault();
				return false;
			}
			return true;
		},
		setContactPreset() {
			this.contact.name = this.carPreset.cicContactName;
			this.contact.phoneNumber = this.carPreset.cicContactPhone
				? this.carPreset.cicContactPhone?.substring(2, this.carPreset.cicContactPhone?.length)
				: null;
			this.contact.email = this.carPreset.cicContactEMail;
			this.contact.citId = this.carPreset.cicCitId;
			this.contact.ownerName = this.carPreset.cicOwnerName;
			this.contact.ownerPhone = this.carPreset.cicOwnerPhone
				? this.carPreset.cicOwnerPhone?.substring(2, this.carPreset.cicOwnerPhone?.length)
				: null;
			this.contact.ownerWorkPhone = this.carPreset.cicOwnerWorkPhone
				? this.carPreset.cicOwnerWorkPhone?.substring(2, this.carPreset.cicOwnerWorkPhone?.length)
				: null;
			this.contact.ownerEMail = this.carPreset.cicOwnerEMail;
			this.contact.ownerAddress = this.carPreset.cicOwnerAddress;
			this.contact.bankName = this.carPreset.cicBankName;
			this.contact.ibanNo = this.carPreset.cicIbanNo;
			this.contact.ownerIdentificationNo = this.carPreset.cicOwnerIdentificationNo;
			this.contact.colorId = this.carPreset.carClrId;
			this.contact.plateNumber = this.carPreset.cidPlateNumber;
			this.contact.chassisNumber = this.carPreset.cidChassisNumber;
			this.contact.isExchangeRequest = this.carPreset.carIsExchangeRequest;
			this.contact.exchangeDescription = this.carPreset.carExchangeDescription;
			this.contact.ownerBirthDate = this.carPreset.cicOwnerBirthDate
				? this.$moment(this.carPreset.cicOwnerBirthDate).format('DD/MM/YYYY')
				: null;
			this.contact.paymentInfo = this.carPreset.cicPaymentInfo;
		},
		phoneNumberErrors() {},
		save() {
			const payload = { key: this.carPreset.carId, ...this.contact };
			this.updateCarDetail(payload).then(() => {
				this.setMessageDialog({
					messageType: 'info',
					subTitle: 'Başarılı',
					text: 'İletişim bilgileri başarılı bir şekilde güncellenmiştir.',
				});
			});
		},
		add() {
			const payload = { phoneNumber: this.contact.phoneNumber };
			this.addBlackList(payload).then(() => {
				this.setMessageDialog({
					messageType: 'info',
					subTitle: 'Başarılı',
					text: 'Telefon numarası kara listeye eklenmiştir.',
				});
			});
		},
	},
	watch: {
		'contact.plateNumber': {
			handler(newValue) {
				this.contact.plateNumber = newValue.toUpperCase();
			},
		},
		'birthDatePicker.menu': {
			handler(val) {
				val && setTimeout(() => (this.$refs.birthDate.activePicker = 'YEAR'));
			},
		},
	},
};
</script>

<style lang="scss" scoped>
:v-deep {
	.plate {
		input {
			text-transform: uppercase !important;
		}
	}
}
</style>
