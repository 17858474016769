<template>
	<div>
		<v-img max-width="135" max-height="100" height="100" width="135" contain :src="blob" v-if="showThumbnail" />
		<v-avatar width="135" height="100" v-else>
			<v-icon v-text="'fas fa-file'" size="60"></v-icon>
		</v-avatar>
	</div>
</template>

<script>
export default {
	name: 'ImageLoader',
	props: {
		image: {
			required: true,
		},
	},
	data() {
		return {
			blob: null,
			showThumbnail: false,
		};
	},

	created() {
		this.$nextTick(async () => {
			this.blob = await this.readAsDataURL(this.image);
		});
	},
	methods: {
		async readAsDataURL(file) {
			const fileReader = new FileReader();

			return new Promise((resolve) => {
				if (file.type.indexOf('image') > -1) {
					this.showThumbnail = true;
					fileReader.onload = () => {
						resolve(fileReader.result);
					};
				} else {
					this.showThumbnail = false;
				}

				fileReader.readAsDataURL(file);
			});
		},
	},
};
</script>

<style></style>
