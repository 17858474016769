/* eslint-disable no-unused-expressions */
import { required, minLength, maxLength } from 'vuelidate/lib/validators';

const commentValidationMixin = {
	validations: {
		comment: {
			required,
			minLength: minLength(10),
			maxLength: maxLength(500),
		},
	},
	methods: {
		// validation methods for this page
		commentErrors() {
			const errors = [];
			const { $dirty, required, minLength, maxLength, $params } = this.$v.comment;
			if (!$dirty) return errors;
			!required && errors.push('Lütfen bir yorum yazınız.');
			!minLength && errors.push(`Yorumunz min ${$params.minLength.min} karakter olmalıdır.`);
			!maxLength && errors.push(`Yorumunz maks ${$params.maxLength.max} karakter olmalıdır.`);
			return errors;
		},
	},
};

export default commentValidationMixin;
