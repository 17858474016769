import { render, staticRenderFns } from "./PriceHistory.vue?vue&type=template&id=486df2c0&scoped=true"
import script from "./PriceHistory.vue?vue&type=script&lang=js"
export * from "./PriceHistory.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "486df2c0",
  null
  
)

export default component.exports